const messages = {
    'de-DE': {
        article: {
            nr: 'Artikelnummer',
            'lang-cluster': 'Sprach-cluster',
            description: 'Beschreibung',
            color: 'Farbe',
            info: 'Info',
            moq: 'MOQ',
            'casepack-amount': 'Menge Casepack',
            gtin: 'GTIN13 / EAN',
            'net-price': 'EH netto',
            'recommended-list-price': 'UVP brutto',
            'amount': 'Menge',
            'total-amount': 'Menge Gesamt',
            'total-amount-brand': 'Total',
            'total-net-price': 'EK Gesamt',
            sheetTotalNetPrice: 'Gesamt',
            priceNotice: 'Abgebildete Preise sind Einzelhandelspreise',
            emptyFilterResult: 'Es gibt keine passenden Ergebnisse. Überprüfen Sie die Filter-Kriterien.',
            amountHidden: 'Artikel mit Mengen sind ausgeblendet.',
            invalidAmount: 'Beachten Sie die Mindestmenge von {min} und die Staffel von {step}',
            showHiddenAmounts: 'anzeigen',
            customerOrderNumber: 'eigene Bestellnummer',
            customerOrderNumberLabel: 'Bestellnr.',
            customerOrderNumberTitle: 'Bestellnr. für {date}'
        },
        orderSheet: {
            hideEmptyPos: 'Nur Positionen mit Menge anzeigen',
            search: 'Artikel nach Nr, Name, Marke filtern'
        },
        orderSentForm: {
            update: 'Änderungen speichern'
        },
        orderRecForm: {
            name: 'Angebotsname',
            customer: 'Kunde',
            message: 'Angebotstext',
            send: 'Senden',
            save: 'Als Vorlage speichern',
            update: 'Vorlage speichern',
            customerMissing: 'Bitte einen Kunden auswählen',
            nameMissing: 'Bitte einen Namen vergeben',
            confirm: 'Angebot an Kunden senden',
            confirmTitle: 'Angebot bestätigen',
            title: 'Angebot',
            sentTitle: 'Angebot',
            cancel: 'Abbrechen',
            back: 'Zurück zur Übersicht',
            exportPDF: 'Als PDF exportieren',
            sendSuccess: 'Das Angebot wurde erfolgreich gesendet.',
            saveSuccess: 'Die Vorlage wurde erfolgreich gespeichert.',
            updateSentSuccess: 'Die Änderungen am Angebot wurden erfolgreich gespeichert.',
            updateSuccess: 'Die Änderungen an der Vorlage wurden erfolgreich gespeichert.',
            unknownError: 'Es ist ein Fehler aufgetreten, bitte versuchen Sie es später nochmal.',
            resendSuccess: 'Das Angebot wurde nochmal per E-Mail versendet.',
            resend: 'Erneut vesenden',
            resendConfirm: 'Sind Sie sicher, dass Sie das Angebot erneut an den Kunden senden möchten?',
            validUntil: 'Gültig bis:'
        },
        orderRecOverview: {
            title: 'Angebotsvorlagen',
            new: 'Neues Angebot erstellen',
            name: 'Vorlagenname',
            modified: 'Zuletzt geändert',
            sentTitle: 'Gesendete Angebote',
            sentDate: 'gesendet am',
            sentName: 'Angebotsname',
            customer: 'Kunde',
            customerNumber: 'Kundennummer',
            filterTemplates: 'Vorlagen filtern',
            filterOffers: 'Angebote filtern',
            deleteTemplateConfirm: 'Sind Sie sicher, dass Sie die Vorlage "{name}" löschen möchten?',
            deleteConfirm: 'Sind Sie sicher, dass Sie das Angebot "{name}" löschen möchten? Der Kunde kann sich alle Positionen weiterhin in seinem Konto ansehen.'
        },
        orderSeason: {
            title: 'Vororder Saisons',
            new: 'Neue Saison anlegen',
            name: 'Name der Saison',
            modified: 'Zuletzt geändert',
            filterOverview: 'Saisons filtern',
            deleteConfirm: 'Sind Sie sicher, dass Sie die Saison löschen wollen?'
        },
        mainNav: {
            orderRecTemplateOverview: 'Angebots-Übersicht',
            orderSeasonOverview: 'Vororder Saisons'
        },
        orderSeasonForm: {
            name: 'Name der Saison',
            startDate: 'Erster Liefertermin',
            endDate: 'Ende der Saison',
            send: 'Senden',
            save: 'Neue Saison speichern',
            update: 'Änderungen speichern',
            articlesMissing: 'Bitte wählen sie Artikel für die Saison aus.',
            nameMissing: 'Bitte einen Namen vergeben',
            baseDiscount: 'Basis-Rabatt',
            volumeDiscount: 'Sortimentsbonus',
            hide: 'Formular ausblenden',
            show: 'Formular einblenden',
            link: 'Web-Adresse für den Kunden',
            saveSuccess: 'Die Saison wurde erfolgreich gespeichert',
            updateSuccess: 'Ihre Änderungen an der Saison wurden gespeichert',
            usePeriods: 'Perioden basiert',
            periodTitle: 'Lieferperioden',
            discountsTitle: 'Rabatte'
        },
        login: {
            title: 'Anmelden',
            name: 'E-Mail / Benutzer',
            pass: 'Passwort',
            submit: 'Login',
            error: 'Die Zugangsdaten sind nicht korrekt.',
            unknownError: 'Es ist ein Fehler aufgetreten.',
            notAllowed: 'Die Vororder ist für Sie nicht freigeschaltet. Bitte wenden Sie sich an unseren Kundenservice.'
        },
        customerSelect: {
            notFound: 'Keine Übereinstimmungen'
        },
        dialog: {
            confirm: 'OK',
            cancel: 'Abbrechen'
        },
        discountInfo: {
            deadlineTitle: 'Vororderschlußtermine',
            discountTitle: 'Vororderrabatte',
            volumeInfoTitle: 'Sortimentsbonus',
            baseInfoTitle: 'Basisrabatt',
            discountInfo: 'Vororderrabatte werden nur bei Bestellungen vor dem Orderschlußtermin gewährt. Der Vororderrabatt entfällt bei Nachbestellungen.',
            discountText: '{percent}% ab Vorordervolumen über {min}',
            totalNetPrice: 'Zwischensumme',
            appliedStaggeredDiscount: 'Vororderabatt',
            appliedBaseDiscount: 'Basisrabatt',
            appliedVolumeDiscount: 'Sortimentsbonus',
            discountedNetPrice: 'Endsumme Vororder',
            volumeInfoDesc: '2% Sortimentsbonus bei Vororder aus allen Warengruppen. Jede Warengruppe muss mindestens 15% vom Auftragswert wiederspiegeln.',
            baseInfoDesc: 'Ab einem Auftragswert größer als {min} € bekommen sie einen {discount}%-igen Basisrabatt. Der Basisrabatt bleibt bei Nachbestellungen bestehen.',
            disclaimer: 'Größere Stornierungen oder Verschiebungen aus der Vororder bedingen einen Wegfall des gewährten Rabattes.'
        },
        volumeDiscountInfo: {
            total: 'Gesamt',
            volume: 'Volumen'
        },
        advanceOrderForm: {
            logoutWithoutSave: 'Abmelden ohne zu speichern',
            logout: 'Abmelden',
            submit: 'Vororder verbindlich abschicken',
            submitConfirm: 'Vielen Dank, ihre Vororder wurde abgesendet.',
            save: 'Speichern',
            saveConfirm: 'Ihre Änderungen wurde erfolgreich gespeichert',
            terms: 'AGB',
            contact: 'Kontakt',
            condition: 'Mit absenden der Vororder akzeptieren Sie die AGB'
        },
        discountScale: {
            addDiscountLevel: 'Staffelrabatt hinzufügen',
            removeLevel: 'Staffelrabatt entfernen',
            minNetSum: 'ab volumen in €',
            percentage: 'Rabatt in %',
            title: 'Rabattstaffel'
        },
        baseDiscount: {
            activeLabel: 'Basisrabatt anbieten',
            conditionLabel: 'ab volumen in €',
            percentLabel: 'Rabatt in %'
        },
        noSeasonFound: {
            title: 'Es wurde keine Vororder-Saison gefunden',
            desc: 'Bitte überprüfen sie den Link',
            logout: 'Abmelden'
        },
        deadlineInfo: {
            orderDeadline: 'Bestellung bis:',
            firstShipment: 'Erstes Lieferdatum ab:'
        },
        advOrderDeadLine: {
            deadline: 'Vororderschlusstermin',
            startDate: 'Erster Liefertermin',
            endDate: 'Letzter Liefertermin'
        },
        brandNavigation: {
            title: 'Marken Auswahl'
        }
    }
}

export default messages;

<template>
    <div class="page-wrapper">
        <advance-order-title><h1>{{ title }}</h1></advance-order-title>
        <order-sheet
            v-if="!loading && !submitted"
            :articles="articles"
            :dates-from="firstShipmentDate"
            :dates-to="lastShipmentDate"
            :shipments="shipments"
            :period-mode="usePeriods"
            :allow-custom-order-number="true"
            @total-price-changed="updateTotalNetPrice"
            multiple-dates
            ref="sheet"
        ></order-sheet>
        <discount-info
            v-if="!loading && !submitted"
            :discount-scale="discountScale"
            :total-net-price="totalOrderNetPrice"
            :base-discount="baseDiscount"
            :volume-discounts="volumeDiscounts"
            :articles="articles"
            :shipments="shipments"
            :deadlines="deadlines"
            :period-mode="usePeriods"
        >
        </discount-info>
        <div v-if="submitted">
            {{ $t('advanceOrderForm.submitConfirm') }}
        </div>
        <div class="action-footer">
            <nav class="link-list">
                <li><a href="#" @click.prevent="openTerms">{{ $t('advanceOrderForm.terms') }}</a></li>
                <li><a href="#" @click.prevent="openContacts">{{ $t('advanceOrderForm.contact') }}</a></li>
            </nav>
            <button @click="logout" :class="{primary: submitted}">
                <span>{{ $t('advanceOrderForm.logout') }}</span>
                &nbsp;<fa-icon icon="sign-out-alt"></fa-icon>
            </button>
            <button v-if="!submitted" @click="saveOrder">
                {{ $t('advanceOrderForm.save') }} <fa-icon icon="save"></fa-icon>
            </button>
            <button v-if="!submitted" class="primary" @click="submitOrder">
                {{ $t('advanceOrderForm.submit') }} <fa-icon icon="share"></fa-icon>
            </button>
            <div class="order-condition" v-if="!submitted">
                {{ $t('advanceOrderForm.condition') }}
            </div>
        </div>
    </div>
</template>

<script>
import OrderSheet from "@/FrontEnd/components/OrderSheet";
import DiscountInfo from "@/FrontEnd/components/DiscountInfo";
import axios from "axios";
import EventBus from "@/FrontEnd/eventbus";
import ShipmentDates from "@/FrontEnd/models/ShipmentDates";
import Terms from "@/FrontEnd/pages/Terms";
import Contact from "@/FrontEnd/pages/Contact";
import AdvanceOrderTitle from "@/FrontEnd/components/AdvanceOrderTitle";
export default {
    name: "AdvanceOrderForm",
    components: {AdvanceOrderTitle, DiscountInfo, OrderSheet},
    props: {
        seasonId: {
            type: String
        }
    },
    data () {
        return {
            loading: true,
            title: '',
            articles: [],
            discountScale: [],
            baseDiscount: null,
            volumeDiscounts: null,
            usePeriods: false,
            deadlines: [],
            totalOrderNetPrice: 0,
            shipments: null,
            submitted: false,
            saving: false
        }
    },
    created() {
        this.shipments = new ShipmentDates();

        let savedDataFound = false;
        let sToken = this.$store.state.sessionToken;
        Promise.all([
            axios.get(`/api/orderSeason/${this.seasonId}?session=${sToken}`)
            .then(response => {
                this.title = response.data.name;
                this.articles = response.data.articles;
                this.usePeriods = response.data.usePeriods || false;
                this.deadlines = response.data.deadlines.map((deadline) => {
                    deadline.date = new Date(Date.parse(deadline.date));
                    deadline.startDate = new Date(Date.parse(deadline.startDate));
                    deadline.endDate = new Date(Date.parse(deadline.endDate)) || new Date(Date.parse(response.data.endDate));
                    return deadline;
                });
                if (response.data.staggeredDiscounts) {
                    this.discountScale = response.data.staggeredDiscounts;
                }
                if (response.data.volumeDiscounts) {
                    this.volumeDiscounts = response.data.volumeDiscounts;
                }
                if (response.data.baseDiscount) {
                    this.baseDiscount = response.data.baseDiscount;
                }
            })
            .catch(error => {
                if (error.response && error.response.status === 404) {
                    this.$router.push({name:'no-season'});
                }
                if (error.response && error.response.status === 401) {
                    this.$store.dispatch('logout', {name: this.$route.name});
                }
            }),
            axios.get(`/api/advanceOrder/${this.seasonId}?session=${sToken}`)
                .then(response => {
                    for(let shipment of response.data.shipments) {
                        let shipmentDate = this.shipments.addDate(new Date(shipment.date));
                        shipmentDate.committed = ('systemOrderNumber' in shipment);
                        for(let articleId in shipment.articleAmounts) {
                            shipmentDate.setAmount(articleId, shipment.articleAmounts[articleId]);
                            shipmentDate.setOrderNumber(shipment.customerOrderNumber || '');
                        }
                    }
                    savedDataFound = true;
                })
                .catch(error => {
                    if (error.response && error.response.status === 401) {
                        this.$store.dispatch('logout', {name: this.$route.name});
                    }
                    if (error.response && error.response.status === 403) {
                        this.$router.push({name:'no-season'});
                    }
                })
        ])
            .finally(() => {
                if (!savedDataFound) {
                    if (this.usePeriods) {
                        this.initPeriodShipments();
                    } else {
                        this.initNormalShipments();
                    }
                }
                this.loading = false;
            });
    },
    computed: {
        firstShipmentDate() {
            let today = new Date();
            for (let deadline of this.deadlines) {
                if (today < deadline.date) {
                    return deadline.startDate;
                }
            }
            return today;
        },
        lastShipmentDate() {
            let today = new Date();
            for (let deadline of this.deadlines) {
                if (today < deadline.date) {
                    return deadline.endDate;
                }
            }
            return today;
        }
    },
    methods: {
        initNormalShipments() {
            let firstDate = new Date();
            if (firstDate < this.firstShipmentDate) {
                firstDate = new Date(this.firstShipmentDate);
            }
            // Calculate day increment for the other days
            let increment = Math.round(
                (this.lastShipmentDate.getTime() - firstDate.getTime()) / (1000 * 3600 * 24) / 3
            );
            this.shipments.addDate(new Date(firstDate)); //Today
            this.shipments.addDate(new Date(firstDate.setDate(firstDate.getDate() + increment)));
            this.shipments.addDate(new Date(firstDate.setDate(firstDate.getDate() + increment)));

            let lastDate = new Date(firstDate.setDate(firstDate.getDate() + increment));
            if (lastDate > this.lastShipmentDate) {
                lastDate = new Date(this.lastShipmentDate);
            }
            this.shipments.addDate(lastDate);
        },
        initPeriodShipments() {
            let today = new Date();
            for (let deadline of this.deadlines) {
                // filter out expired periods
                if (today >= deadline.date) {
                    continue;
                }
                this.shipments.addDate(
                    (today < deadline.startDate) ? new Date(deadline.startDate) : new Date(today),
                    { start: deadline.startDate, end: deadline.endDate}
                );
            }
        },
        updateTotalNetPrice(total) {
            this.totalOrderNetPrice = total;
        },
        submitOrder() {
            let shipments = this.$refs.sheet.getShipments().serialize();
            let sToken = this.$store.state.sessionToken;
            axios.post(`/api/advanceOrder/${this.seasonId}?session=${sToken}`, shipments)
                .then(() => {
                    this.dirtyFlag = false;
                    this.submitted = true;
                })
                .catch();
        },
        saveOrder() {
            let shipments = this.$refs.sheet.getShipments().serialize();
            let sToken = this.$store.state.sessionToken;
            this.saving = true;
            axios.put(`/api/advanceOrder/${this.seasonId}?session=${sToken}`, shipments)
                .then(() => {
                    this.dirtyFlag = false;
                    EventBus.$emit('modal-requested', this.$t('advanceOrderForm.saveConfirm'));
                })
                .catch()
                .finally(() => {
                    this.saving = false;
                });
        },
        logout() {
            this.$store.dispatch('logout');
        },
        openTerms() {
            EventBus.$emit('modal-component-requested', Terms);
        },
        openContacts() {
            EventBus.$emit('modal-component-requested', Contact);
        }
    }
}
</script>

<style scoped lang="scss">
    @import "@/FrontEnd/styles/input";
    @import "@/FrontEnd/styles/layout";
    h1 {
        margin: $slim-padding 0;
    }
    div.page-wrapper {
        height: 100%;
        box-sizing: border-box;
        padding: $regular-padding;
        overflow-x: hidden;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
    }
    div.action-footer {
        @include footer-action-bar;
        &>.order-condition {
            margin: .2rem .3125rem;
        }
        &>.link-list {
            float: left;
            margin: 0;
            padding: 0;
            display: flex;
            flex-direction: row;
            height: 100%;
        }
        &>.link-list li {
            margin: 0;
            padding: 0 .4rem;
            list-style: none;
            display: block;
            height: 100%;
            line-height: 2.6rem;

            &>a {
                vertical-align: middle;
            }
        }
    }
</style>
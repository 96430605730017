<template>
    <tbody class="grouped-contents">
        <tr class="brand-header" :id="anchor">
            <th :colspan="singleSelection ? 8 : calculateColSpan(multipleDates ? 2 : 1)">
                {{ brandName }}
            </th>
            <th v-if="singleSelection">
                <input
                    type="checkbox"
                    v-model="areAllSelected"
                    :indeterminate.prop="areSomeSelected"
                >
            </th>
            <th v-if="multipleDates"></th>
            <th v-if="singleSelection === false"></th>
        </tr>
        <slot
            v-for="article in articles"
            name="article"
            :article="article"
        ></slot>
        <tr class="brand-footer">
            <td :colspan="calculateColSpan(shipmentDates.getAmountOfDates() + (singleSelection ? 0 : 1))">
                <span v-if="singleSelection === false">{{ $t('article.total-amount-brand') }} {{ brandName }}</span>
            </td>
            <td v-for="date in shipmentDates.dates" :key="date.id"></td>
            <td v-if="singleSelection === false" class="number">{{ $n(brandTotalNetPrice, 'currency') }}</td>
        </tr>
    </tbody>
</template>

<script>
import ShipmentDates from "@/FrontEnd/models/ShipmentDates";
import {getBrandAnchor} from "@/FrontEnd/utils/brandAnchorManager";

export default {
    name: "OrderSheetBrand",
    props: {
        brandId: {
            type: String
        },
        brandName: {
            type: String
        },
        articles: {
            type: Array
        },
        shipmentDates: {
            type: ShipmentDates
        },
        singleSelection: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        colspan: {
            type: Number
        }
    },
    computed: {
        anchor() {
            return getBrandAnchor(this.brandId);
        },
        multipleDates() {
            return this.shipmentDates.getAmountOfDates() > 1;
        },
        brandTotalNetPrice() {
            let total = 0;
            for (let article of this.articles) {
                let articleAmount = 0;
                this.shipmentDates.getAllDates().forEach((date) => {
                    articleAmount += date.getAmount(article.id);
                });
                total += (articleAmount * article.netPrice);
            }
            return total;
        },
        areAllSelected: {
            get() {
                for (let article of this.articles) {
                    if (this.shipmentDates.getFirst().getAmount(article.id) < 1) {
                        return false;
                    }
                }
                return true;
            },
            set(selected) {
                for (let article of this.articles) {
                    this.shipmentDates.getFirst().setAmount(article.id, selected ? 1 : 0);
                }
            }
        },
        areSomeSelected() {
            let foundSome = false;
            let missedSome = false;
            for (let article of this.articles) {
                if (this.shipmentDates.getFirst().getAmount(article.id) < 1) {
                    missedSome = true;
                }
                if (this.shipmentDates.getFirst().getAmount(article.id) > 0) {
                    foundSome = true;
                }
            }
            return (foundSome && missedSome);
        }
    },
    methods: {
        calculateColSpan(subtract) {
            if (!subtract) {
                subtract = 0;
            }
            return (this.colspan - subtract);
        }
    }
}
</script>

<style scoped lang="scss">
    .brand-header>th {
        background-color: $table-group-header-bg-color;
        color: $table-group-header-font-color;
        padding-top: $regular-padding;
        position: -webkit-sticky;
        position: sticky;
        top: 3rem;
    }
</style>
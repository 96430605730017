<template>
    <div class="sheet-wrapper">
    <table ref="article-table">
        <thead class="main-head">
            <order-sheet-shipment-header
                :shipment-dates="shipmentDates"
                :single-selection="singleSelection"
                :first-shipment-date="firstShipmentDate"
                :last-shipment-date="lastShipmentDate"
                :set-col-num="setColumnNumber"
                :brands="brands"
                :period-mode="periodMode"
            >
            </order-sheet-shipment-header>
        </thead>
        <tr class="loading" v-if="loading">
            <td :colspan="calculateColSpan()"><loading-spinner></loading-spinner></td>
        </tr>
        <tr class="empty-state" v-if="!loading && filteredArticles.length < 1 && (articleSearchFilter || hideEmptyPositions)">
            <td :colspan="calculateColSpan()">
                {{ $t('article.emptyFilterResult') }}
            </td>
        </tr>
        <order-sheet-brand
            v-for="brand in filteredArticles"
            :key="brand.id"
            :articles="brand.articles"
            :brand-id="brand.id"
            :brand-name="brand.name"
            :shipment-dates="shipmentDates"
            :single-selection="singleSelection"
            :disabled="disabled"
            :colspan="columnNumber"
        >
            <template v-slot:article="props">
                <order-sheet-article-row
                    :article="props.article"
                    :shipment-dates="shipmentDates"
                    :single-selection="singleSelection"
                    :disabled="disabled"
                >
                </order-sheet-article-row>
            </template>
        </order-sheet-brand>
        <order-sheet-footer
            :shipment-dates="shipmentDates"
            :hidden-amount-hint="hiddenAmount"
            :show-totals="singleSelection === false"
            :brands="brands"
            :allow-custom-order-number="allowCustomOrderNumber"
            @reset-filter-and-show-amounts="$emit('reset-filter-and-show-amounts')"
        ></order-sheet-footer>
    </table>
    </div>
</template>

<script>
import ShipmentDates from "@/FrontEnd/models/ShipmentDates";
import OrderSheetBrand from "@/FrontEnd/components/OrderSheetBrand";
import axios from 'axios';
import LoadingSpinner from "@/FrontEnd/components/LoadingSpinner";
import OrderSheetShipmentHeader from "@/FrontEnd/components/OrderSheetShipmentHeader";
import OrderSheetArticleRow from "@/FrontEnd/components/OrderSheetArticleRow";
import OrderSheetFooter from "@/FrontEnd/components/OrderSheetFooter";
import EventBus from "@/FrontEnd/eventbus";
import {buildBrandIndex} from "@/FrontEnd/utils/brandAnchorManager";

export default {
    name: "OrderSheet",
    components: {
        OrderSheetFooter,
        OrderSheetArticleRow, OrderSheetShipmentHeader, LoadingSpinner, OrderSheetBrand},
    created() {
        this.initShipmentDates();

        if (this.articles) {
            this.brands = this.articles;
            buildBrandIndex(this.brands, true);
        } else {
            let sToken = this.$store.state.sessionToken;
            this.loading = true;
            let url = `/api/articlesByBrand?session=${sToken}`;
            if (!this.usePresalePrice) {
                url += '&presale=false';
            }
            axios.get(url)
                .then(response => {
                    this.brands = response.data;
                    buildBrandIndex(this.brands, true);
                })
                .catch(error => {
                    if (error.response && error.response.status === 401) {
                        this.$store.dispatch('logout', {name: this.$route.name});
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
        }

        EventBus.$on('router-hash-changed', (hash) => {
            let elem = document.getElementById(hash);
            if (elem !== null) {
                elem.scrollIntoView({behavior: 'smooth'});
            }
        })
    },
    beforeUpdate() {
        // Rebuild index in case a filter has been applied
        buildBrandIndex(this.brands, true);
    },
    props: {
        multipleDates: {
            type: Boolean,
            default: false
        },
        singleSelection: {
            type: Boolean,
            default: false
        },
        articles: {
            type: Array
        },
        datesFrom: {
            type: Date
        },
        datesTo: {
            type: Date
        },
        hideEmptyPositions: {
            type: Boolean,
            default: false
        },
        articleSearchFilter: {
            type: String,
            default: ''
        },
        disabled: {
            type: Boolean,
            default: false
        },
        shipments: {
            type: ShipmentDates,
            default: null
        },
        allowCustomOrderNumber: {
            type: Boolean,
            default: false
        },
        periodMode: {
            type: Boolean,
            default: false
        },
        usePresalePrice: {
            type: Boolean,
            default: true
        }
    },
    data() {
        let defaultShipmentDates = new ShipmentDates();
        let today = new Date();
        let in3Months = new Date();
        in3Months.setMonth(today.getMonth() + 3);
        return {
            defaultShipmentDates,
            brands: [],
            loading: false,
            hiddenAmount: false,
            defaultFirstShipmentDate: today,
            defaultLastShipmentDate: in3Months,
            columnNumber: 0
        };
    },
    methods: {
        initShipmentDates() {
            let today = new Date();
            if(this.singleSelection || this.shipments === null) {
                this.defaultShipmentDates.addDate(new Date(today));
            }

            this.$emit('total-price-changed', .0);
        },
        getShipments() {
            return this.shipmentDates;
        },
        getSelectedArticles() {
            return this.shipmentDates.getFirst().getArticlesWithAmount();
        },
        calculateColSpan() {
            return 9 + this.shipmentDates.getAmountOfDates() + (this.shipmentDates.getAmountOfDates() > 1 ? 1 : 0);
        },
        hasAnyAmount() {
            return this.totalAmount > 0;
        },
        showHiddenAmountHint() {
            this.hiddenAmount = true;
        },
        hideHiddenAmountHint() {
            this.hiddenAmount = false;
        },
        setColumnNumber(num) {
            this.columnNumber = num;
        }
    },
    computed: {
        shipmentDates() {
            if (this.shipments !== null) {
                return this.shipments;
            }
            return this.defaultShipmentDates;
        },
        firstShipmentDate() {
            if (this.datesFrom) {
                return this.datesFrom;
            }
            return this.defaultFirstShipmentDate;
        },
        lastShipmentDate() {
            if (this.datesTo) {
                return this.datesTo;
            }
            return this.defaultLastShipmentDate;
        },
        totalNetPrice() {
            let total = 0;
            for (let brand of this.brands) {
                for (let article of brand.articles) {
                    total += (this.shipmentDates.getArticleAmount(article.id) * article.netPrice);
                }
            }
            this.$emit('total-price-changed', total);
            return total;
        },
        totalAmount() {
            let total = 0;
            for (let brand of this.brands) {
                for (let article of brand.articles) {
                    total += this.shipmentDates.getArticleAmount(article.id);
                }
            }
            return total;
        },
        filteredArticles() {
            let result = [];
            this.hideHiddenAmountHint();
            this.brands.forEach(brand => {
                // create deep copy
                brand = JSON.parse(JSON.stringify(brand));
                brand.articles = brand.articles.filter((article) => {
                    if (this.articleSearchFilter) {
                        if(
                            !article.name.toLowerCase().includes(this.articleSearchFilter.toLowerCase())
                            && !article.nr.toLowerCase().includes(this.articleSearchFilter.toLowerCase())
                            && !article.gtin.toLowerCase().includes(this.articleSearchFilter.toLowerCase())
                        ) {
                            if (this.shipmentDates.hasArticleAmount(article.id)) {
                                this.showHiddenAmountHint();
                            }
                            return false;
                        }
                    }

                    if (this.hideEmptyPositions && !this.shipmentDates.hasArticleAmount(article.id)) {
                        return false;
                    }
                    return true;
                });
                if (brand.articles.length > 0) {
                    result.push(brand);
                }
            });
            return result;
        }
    }
}
</script>

<style lang="scss">
    @import "@/FrontEnd/styles/table";
    .sheet-wrapper {
        box-sizing: border-box;
        width: 100%;
        overflow-y: scroll;
        min-height: 30rem;
    }

    tbody td input, tfoot td input {
        min-width: 8rem;
        width: 100% !important;
    }
</style>
<template>
    <tr>
        <th>{{ $t('article.nr') }}</th>
        <!--<th>{{ $t('article.lang-cluster') }}</th>-->
        <th>{{ $t('article.description') }}</th>
        <th>{{ $t('article.info') }}</th>
        <th>{{ $t('article.casepack-amount') }}</th>
        <th>{{ $t('article.moq') }}</th>
        <th>{{ $t('article.gtin') }}</th>
        <th>{{ $t('article.net-price') }}</th>
        <th>{{ $t('article.recommended-list-price') }}</th>
        <th
            v-for="date in shipmentDates.dates"
            :key="date.id"
            :class="{'shipment-date': !singleSelection, 'single-select': singleSelection}"
        >
            <input
                v-if="singleSelection"
                v-model="areAllSelected"
                type="checkbox"
                :indeterminate.prop="areSomeSelected"
            >
            <span v-else-if="periodMode === true || shipmentDates.getAmountOfDates() > 1">
                <span v-if="periodMode === true" class="period-duration">
                    {{formatDate(date.startDate)}} - {{formatDate(date.endDate)}}
                </span>
                <datepicker
                    class="shipment-date"
                    :language="de"
                    monday-first
                    v-model="date.date"
                    :disabled-dates="getDisabledDates(date)"
                    :format="formatDate"
                ></datepicker>
            </span>
            <span v-else>{{ $t('article.amount') }}</span>
        </th>
        <th v-if="shipmentDates.getAmountOfDates() > 1">{{ $t('article.total-amount') }}</th>
        <th v-if="!singleSelection">{{ $t('article.total-net-price') }}</th>
    </tr>
</template>

<script>
import {de} from 'vuejs-datepicker/dist/locale';
import Datepicker from 'vuejs-datepicker';
import ShipmentDates from "@/FrontEnd/models/ShipmentDates";

export default {
    name: "OrderSheetShipmentHeader",
    components: {Datepicker},
    props: {
        shipmentDates: {
            type: ShipmentDates
        },
        singleSelection: {
            type: Boolean,
            default: false
        },
        firstShipmentDate: {
            type: Date
        },
        lastShipmentDate: {
            type: Date
        },
        periodMode: {
            type: Boolean,
            default: false
        },
        setColNum: {
            type: Function
        },
        brands: {
            type: Array
        }
    },
    data () {
        return {
            de
        };
    },
    created() {
        // Calculate column number and tell parent via callback
        this.calcColNum();
    },
    computed: {
        areAllSelected: {
            get() {
                for (let brand of this.brands) {
                    for (let article of brand.articles) {
                        if (this.shipmentDates.getFirst().getAmount(article.id) < 1) {
                            return false;
                        }
                    }
                }
                return true;
            },
            set(selected) {
                for (let brand of this.brands) {
                    for (let article of brand.articles) {
                        this.shipmentDates.getFirst().setAmount(article.id, selected ? 1 : 0);
                    }
                }
            }
        },
        areSomeSelected() {
            let foundSome = false;
            let missedSome = false;
            for (let brand of this.brands) {
                for (let article of brand.articles) {
                    if (this.shipmentDates.getFirst().getAmount(article.id) < 1) {
                        missedSome = true;
                    }
                    if (this.shipmentDates.getFirst().getAmount(article.id) > 0) {
                        foundSome = true;
                    }
                }
            }
            return (foundSome && missedSome);
        }
    },
    methods: {
        getDisabledDates(date) {
            if (this.periodMode === true) {
                let end = new Date(date.endDate);
                end.setDate(end.getDate() + 1);
                return {
                    to: date.startDate,
                    from: end
                }
            }
            let end = new Date(this.lastShipmentDate);
            end.setDate(end.getDate() + 1);
            return {
                to: this.firstShipmentDate,
                from: end
            }
        },
        calcColNum() {
            // Number of fixed columns
            let colNum = 9;
            // plus one column for each shipment date
            colNum += this.shipmentDates.getAmountOfDates();
            if (this.shipmentDates.getAmountOfDates() > 1) {
                // if there is more than one shipment date, we have an additional column for total amount
                colNum += 1;
            }
            if (this.singleSelection) {
                // if this is a single selection, we don't have a total net price
                colNum -= 1;
            }
            // tell parent
            this.setColNum(colNum);
        },
        formatDate(date) {
            return this.$d(date, 'short');
        }
    }
}
</script>

<style scoped lang="scss">
    @import "@/FrontEnd/styles/input";

    th.single-select {
        text-align: center;
    }
    th.shipment-date {
        min-width: 9rem;

        &>span {
            display: inline-block;
            width: 100%;

            & .shipment-date /deep/ input {
                @include input-style;
                width: 100%;
            }
        }
    }

    .period-duration {
        font-size: .8rem;
    }
</style>

<template>
    <div class="discount-info-wrapper">
        <div class="discount-info-tabs">
            <nav>
                <li v-if="hasDeadlines">
                    <a href="#" :class="{active: infoTab === 'deadline'}" @click.prevent="infoTab = 'deadline'">
                        {{ $t('discountInfo.deadlineTitle') }}
                    </a>
                </li>
                <li v-if="hasDiscountScale">
                    <a href="#" :class="{active: infoTab === 'scale'}" @click.prevent="infoTab = 'scale'">
                        {{ $t('discountInfo.discountTitle') }}
                    </a>
                </li>
                <li v-if="hasVolumeDiscount">
                    <a href="#" :class="{active: infoTab === 'volume'}" @click.prevent="infoTab = 'volume'">
                        {{ $t('discountInfo.volumeInfoTitle') }}
                    </a>
                </li>
                <li v-if="hasBaseDiscount">
                    <a href="#" :class="{active: infoTab === 'base'}" @click.prevent="infoTab = 'base'">
                        {{ $t('discountInfo.baseInfoTitle') }}
                    </a>
                </li>
            </nav>
            <aside v-if="hasDeadlines && infoTab === 'deadline'">
                <table>
                    <thead>
                        <tr>
                            <th>{{ $t('deadlineInfo.orderDeadline') }}</th>
                            <th>{{ $t('deadlineInfo.firstShipment') }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(deadline, index) in deadlines" :key="index">
                            <td>{{ $d(deadline.date, 'short') }}</td>
                            <td>{{ $d(deadline.startDate, 'short') }}</td>
                        </tr>
                    </tbody>
                </table>
            </aside>
            <aside v-if="hasDiscountScale && infoTab === 'scale'">
                <p>{{ $t('discountInfo.discountInfo') }}</p>
                <table class="discount-scale-table">
                    <tbody>
                        <tr v-for="discount in discountScale" :key="discount.min">
                            <td>
                                {{ $t('discountInfo.discountText', {min:$n(discount.min, 'currency'), percent:discount.percent}) }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </aside>
            <aside v-if="hasVolumeDiscount" v-show="infoTab === 'volume'">
                <p>{{ $t('discountInfo.volumeInfoDesc') }}</p>
                <volume-discount-info
                    v-if="shipments !== null"
                    :shipments="shipments"
                    :articles="articles"
                    :total-net-price="totalNetPrice"
                    :volume-discounts="volumeDiscounts"
                    @discount-volume-changed="handleVolumeDiscountChange"
                ></volume-discount-info>
            </aside>
            <aside v-if="hasBaseDiscount && infoTab === 'base'">
                <p>
                    {{ $t('discountInfo.baseInfoDesc', this.baseDiscount) }}
                </p>
            </aside>
        </div>
        <div class="discount-calc-table">
            <table>
                <tbody>
                <tr>
                    <td>{{ $t('discountInfo.totalNetPrice') }}</td>
                    <td></td>
                    <td>{{ $n(totalNetPrice, 'currency') }}</td>
                </tr>
                <tr v-if="hasDiscountScale">
                    <td>{{ $t('discountInfo.appliedStaggeredDiscount') }}</td>
                    <td>{{ staggeredDiscountPercentage }}%</td>
                    <td>{{ $n(staggeredDiscountAmount, 'currency') }}</td>
                </tr>
                <tr v-if="hasBaseDiscount">
                    <td>{{ $t('discountInfo.appliedBaseDiscount') }}</td>
                    <td>{{ baseDiscountPercentage }}%</td>
                    <td>{{ $n(baseDiscountAmount, 'currency') }}</td>
                </tr>
                <tr v-if="hasVolumeDiscount">
                    <td>{{ $t('discountInfo.appliedVolumeDiscount') }}</td>
                    <td>{{ volumeDiscountPercentage }}%</td>
                    <td>{{ $n(volumeDiscountAmount, 'currency') }}</td>
                </tr>
                <tr>
                    <td>{{ $t('discountInfo.discountedNetPrice') }}</td>
                    <td></td>
                    <td>{{ $n(finalNetPrice, 'currency') }}</td>
                </tr>
                </tbody>
            </table>
            <span>{{ $t('discountInfo.disclaimer') }}</span>
        </div>
    </div>
</template>

<script>
import ShipmentDates from "@/FrontEnd/models/ShipmentDates";
import VolumeDiscountInfo from "@/FrontEnd/components/VolumeDiscountInfo";

export default {
    name: "DiscountInfo",
    components: {VolumeDiscountInfo},
    props: {
        discountScale: {
            type: Array
        },
        baseDiscount: {
            type: Object,
            default: null
        },
        volumeDiscounts: {
            type: Array,
            default: null
        },
        periodMode: {
            type: Boolean,
            default: false
        },
        articles: Array,
        shipments: ShipmentDates,
        deadlines: Array
    },
    data () {
        return {
            infoTab: null,
            volumeDiscountApplies: false
        };
    },
    created() {
        switch (true) {
            case this.hasDeadlines:
                this.infoTab = 'deadline';
                break;
            case this.hasDiscountScale:
                this.infoTab = 'scale';
                break;
            case this.hasVolumeDiscount:
                this.infoTab = 'volume';
                break;
            case this.hasBaseDiscount:
                this.infoTab = 'base';
                break;
        }
    },
    computed: {
        totalNetPrice() {
            return this.shipments.getTotalNetPrice(this.articles);
        },
        hasDeadlines() {
            return this.deadlines !== null && this.deadlines.length > 0 && this.periodMode === false;
        },
        hasDiscountScale() {
            return this.discountScale !== null && this.discountScale.length > 0;
        },
        hasBaseDiscount() {
            return this.baseDiscount !== null;
        },
        hasVolumeDiscount() {
            return this.volumeDiscounts !== null
                && this.volumeDiscounts.length > 0;
        },
        baseDiscountPercentage() {
            if (this.hasBaseDiscount && this.totalNetPrice > this.baseDiscount.min) {
                return this.baseDiscount.discount;
            }
            return 0;
        },
        staggeredDiscountPercentage() {
            let discounts = JSON.parse(JSON.stringify(this.discountScale));
            for (let discount of discounts.sort((a, b) => (b.min >= a.min ? 1 : -1))) {
                if (this.totalNetPrice > discount.min) {
                    return discount.percent;
                }
            }
            return 0;
        },
        volumeDiscountPercentage() {
            if (!this.hasVolumeDiscount || this.shipments === null || !this.volumeDiscountApplies) {
                return 0;
            }
            return 2;
        },
        staggeredDiscountAmount() {
            return this.totalNetPrice * (this.staggeredDiscountPercentage / 100);
        },
        baseDiscountAmount() {
            return this.totalNetPrice * (this.baseDiscountPercentage / 100);
        },
        volumeDiscountAmount() {
            return this.totalNetPrice * (this.volumeDiscountPercentage / 100);
        },
        finalNetPrice() {
            return (
                this.totalNetPrice - this.staggeredDiscountAmount - this.baseDiscountAmount - this.volumeDiscountAmount
            );
        },
        flatArticleIds() {
            let articles = JSON.parse(JSON.stringify(this.articles));
            return articles.reduce((flatArticles, brand) => flatArticles.concat(brand.articles), []);
        }
    },
    methods: {
        handleVolumeDiscountChange({applies}) {
            this.volumeDiscountApplies = applies;
        }
    }
}
</script>

<style scoped lang="scss">
    .discount-info-wrapper {
        margin-top: .4rem;
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        justify-items: flex-end;
        flex-wrap: wrap;

        &>.discount-info-tabs {
            flex-grow: 1;
            width: 60%;
            min-width: 40rem;

            &>nav {
                display: flex;
                flex-direction: row;
                padding: 0;
                margin: 0;

                &>li {
                    margin: 0;
                    padding: 0;
                    list-style: none;
                    width: auto;

                    &>a {
                        position: relative;
                        display: block;
                        padding: .6rem;
                        border: .1rem solid #dddddd;
                        border-bottom: none;
                        width: auto;
                        text-decoration: none;
                        background: linear-gradient(to bottom, #fff 0%, #eee 100%);
                        z-index: 0;


                        &:active, &:visited, &:focus {
                            text-decoration: none;
                            color: currentColor;
                        }
                        &:hover {
                            color: #004489;
                            background: #fff;
                        }
                        &.active {
                            background: #fff;
                            z-index: 5;
                            &:hover {
                                color: currentColor;
                            }
                        }
                    }
                }
            }
            &>aside {
                position: relative;
                margin-top: -.1rem;
                box-sizing: border-box;
                border: .1rem solid #dddddd;
                padding: .4rem;
                background: #fff;
                z-index: 3;
            }
        }
        &>.discount-calc-table {
            flex-grow: 1;
            width: 40%;
            min-width: 30rem;
            box-sizing: border-box;
            padding: .4rem;

            & span {
                display: block;
                text-align: left;
                margin: .4rem 0;
            }
        }
        .discount-scale-table {
            margin: 0 auto;
            width: auto;
        }
    }
</style>